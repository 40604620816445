import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ContentDisplay from '../components/content-display/content-display';
import DefaultLayout from '../components/default-layout/default-layout';
import SEO from '../components/seo/seo';
import SlideshowSmall from '../components/slideshow-small/slideshow-small';
import { get } from '../helpers/hooks-helpers';
import { useCurrentFilter } from '../hooks/use-current-filter';

const BasicPage = ({ data }) => {
  const content = data.nodePage;
  // For the sidebar's slideshow-small component, we want to show all content added to the
  // Side Bar Promo vocabulary that is not expired.
  const sidebarData = useCurrentFilter(get(data, 'SidebarPromo.relationships.field_promoted_content', []));
  // Reformat the metatag data from Drupal in a way we can use.
  const metatags = content.metatag.reduce((accumulator, currentVal) => {
    return {
      ...accumulator,
      [currentVal.attributes.name]: currentVal.attributes.content
    };
  }, {});
  // Pass content into the sidebar. I'm limiting this to 5 upcoming events.
  content.sidebarContent = (
    <SlideshowSmall data={sidebarData.slice(0, 5)} />
  );

  return (
    <DefaultLayout data={data}>
      <SEO
        {...metatags}
        pageUrl={content.path.alias}
        siteConfig={data.site.siteMetadata}
      />
      <ContentDisplay {...content} />
    </DefaultLayout>
  );
};

BasicPage.propTypes = {
  data: PropTypes.object
};

export const query = graphql`
  query BasicPageQuery($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    nodePage(path: { alias: { eq: $slug } }) {
      title
      drupal_id
      path {
        alias
      }
      body {
        processed
      }
      metatag: metatag {
        attributes {
          name
          content
        }
      }
    }
    SidebarPromo: taxonomyTermPromotion(
      drupal_id: { eq: "4a1f98b8-f191-496d-ac7d-db604fcd6840" }
    ) {
      ...Promoted
    }
  }
`;

export default BasicPage;
