import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import throttle from 'lodash/throttle';

import ContentTeaser from '../content-teaser/content-teaser';
import Button from '../button/button';
import { ReactComponent as Arrow } from './assets/arrow.svg';
import { ReactComponent as Play } from '../hero-slideshow/assets/play.svg';
import { ReactComponent as Pause } from '../hero-slideshow/assets/pause.svg';

// Imports the CSS from slick carousel.
import 'slick-carousel/slick/slick.css';
// CSS Module styles for the slideshow-small.
import styles from './slideshow-small.module.scss';

export default class SlideshowSmall extends Component {
  constructor() {
    super();

    this.state = {
      arrows: true,
      centerMode: false,
      isPlaying: true,
      slidesToShow: 1
    };

    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.handleResize = throttle(this.handleResize.bind(this), 500);
  }

  // On mount, run through the resize logic and add a listener for future
  // resize events.
  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  // On resize, update the number of slides that will show and hide the arrows
  // on mobile.
  handleResize() {
    const breakpoints = {
      medium: 576,
      large: 992
    };

    const width = window.innerWidth;

    if (width >= breakpoints.large) {
      this.setState({
        slidesToShow: 1,
        centerMode: false
      });
    }
    else if (width > breakpoints.medium) {
      this.setState({
        slidesToShow: 1,
        centerMode: true
      });
    }
    else {
      this.setState({
        slidesToShow: 1,
        centerMode: false
      });
    }
  }

  play() {
    this.setState({ isPlaying: true });
    this.slider.slickPlay();
  }
  pause() {
    this.setState({ isPlaying: false });
    this.slider.slickPause();
  }

  buttons() {
    return this.state.isPlaying ? (
      <Button className={styles.pause} onClick={this.pause}>
        <span className="visually-hidden">Pause</span>
        <Pause className={styles.icon} height="16" width="16" />
      </Button>
    ) : (
      <Button className={styles.play} onClick={this.play}>
        <span className="visually-hidden">Play</span>
        <Play className={styles.icon} height="16" width="16" />
      </Button>
    );
  }

  render() {
    // Settings for react-slick.
    // https://react-slick.neostack.com/docs/api#adaptiveHeight
    const settings = {
      appendDots: (dots) => (
        <ul className={styles.dots}>
          <li>{this.buttons()}</li>
          {dots}
        </ul>
      ),
      autoplay: true,
      autoplaySpeed: 7500,
      infinite: true,
      speed: 500,
      slidesToShow: this.state.slidesToShow,
      centerMode: this.state.centerMode,
      slidesToScroll: 1,
      dots: true,
      dotsClass: styles.dots,
      nextArrow: <SlickButton>Next Slide</SlickButton>,
      prevArrow: <SlickButton>Previous Slide</SlickButton>,
    };

    const slides = this.props.data;
    return (
      <section aria-label={this.props.title} className={styles.slider}>
      { this.props.title &&
        <h2 className={styles.title}>
          {this.props.title}
        </h2>
      }

        <Slider ref={(slider) => (this.slider = slider)} {...settings}>
          {slides.map((slide) => (
            <div className={styles.slide} key={slide.drupal_id}>
              <ContentTeaser data={slide} />
            </div>
          ))}
        </Slider>
      </section>
    );
  }
}

SlideshowSmall.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

// Abstracting this component to prevent currentSlide and slideCount from
// being passed to <button>, which was triggering a warning from React.
const SlickButton = ({ currentSlide, slideCount, ...props }) => {
  return <button {...props}>{props.children}</button>;
};

SlickButton.propTypes = {
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number
};
